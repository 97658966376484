import {
  HttpErrorResponse,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { ToastService } from '@libs/services';
import { AuthService } from '@modules/auth/services/auth.service';
import {
  BehaviorSubject,
  catchError,
  filter,
  switchMap,
  take,
  throwError,
} from 'rxjs';

export const errorApiHandlerInterceptor: HttpInterceptorFn = (req, next) => {
  const toastService = inject(ToastService);
  const authService = inject(AuthService);
  const router = inject(Router);

  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      const errorResponse = error;
      switch (errorResponse.error.statusCode) {
        case 400:
          // * Specific error message
          if (typeof errorResponse.error.message !== 'string') {
            errorResponse.error.message.forEach((errorMessage: string) => {
              toastService.showErrorToast({ message: errorMessage });
            });
          } else {
            const checkResponseMessage = errorResponse.error.message.split('|');
            if (checkResponseMessage.length < 1) {
              toastService.showErrorToast({
                message: errorResponse.error.message,
              });
            }
          }
          break;

        case 401: {
          const refreshTokenSubject: BehaviorSubject<unknown> =
            new BehaviorSubject<unknown>(null);

          let isRefreshing = false;

          if (authService.getAuthenticatedUser()?.refreshToken) {
            if (!isRefreshing) {
              isRefreshing = true;
              refreshTokenSubject.next(null);
              const refreshToken =
                authService.getAuthenticatedUser()?.refreshToken;

              return authService.refreshToken(refreshToken).pipe(
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                switchMap((res: any) => {
                  isRefreshing = false;
                  refreshTokenSubject.next(res.token);
                  return next(setTokenHeader(req, res.token));
                }),
                catchError((err) => {
                  isRefreshing = false;
                  authService.removeAuthenticatedSession();
                  toastService.showErrorToast({
                    message: 'Your login session has expired.',
                  });

                  isRefreshing = false;
                  router.navigate(['/auth/login']);
                  return throwError(() => err);
                }),
              );
            }
            return refreshTokenSubject.pipe(
              filter((token) => token !== null),
              take(1),
              switchMap((token) =>
                next(setTokenHeader(req, token?.toString())),
              ),
            );
          }
          break;
        }

        case 406:
          console.log(errorResponse.error.message);

          // * Specific error message
          if (typeof errorResponse.error.message !== 'string') {
            errorResponse.error.message.forEach((errorMessage: string) => {
              toastService.showErrorToast({ message: errorMessage });
            });
          } else {
            const checkResponseMessage = errorResponse.error.message.split('|');

            if (checkResponseMessage.length <= 1) {
              toastService.showWarningToast({
                title: errorResponse.error.error ?? 'Error',
                message: errorResponse.error.message,
              });
            }
          }
          break;

        case 500:
          toastService.showErrorToast({
            message: 'Internal server error, please contact administrator.',
          });
          break;

        case 503:
          toastService.showErrorToast({
            message: 'Server unavailable, please contact administrator.',
          });
          break;
      }
      return throwError(() => error);
    }),
  );
};

const setTokenHeader = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  request: HttpRequest<any>,
  token: string | undefined,
) => {
  if (token) {
    return (request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    }));
  } else {
    return request;
  }
};
