import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { IPopularSupport } from '../../interfaces';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { InputTextModule } from 'primeng/inputtext';
import { debounce } from 'lodash';

@Component({
  selector: 'app-support-header',
  standalone: true,
  imports: [IconFieldModule, InputIconModule, InputTextModule],
  templateUrl: './support-header.component.html',
  styleUrl: './support-header.component.scss',
})
export class SupportHeaderComponent {
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onSearch = new EventEmitter<string>();

  listPopularSupport: IPopularSupport[] = [];
  debouncedSearchLocation = debounce(this._onSearch.bind(this), 500);

  constructor(private _router: Router) {}

  encode(value: string): string {
    return encodeURIComponent(value);
  }

  setPopularSupport(list: IPopularSupport[]): void {
    this.listPopularSupport = list;
  }

  onChange(e: KeyboardEvent): void {
    this.debouncedSearchLocation((e.target as HTMLInputElement).value);
  }

  private _onSearch(search: string): void {
    this.onSearch.emit(search);
  }
}
