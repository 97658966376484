import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  Router,
  RouterLink,
  RouterLinkActive,
  RouterOutlet,
} from '@angular/router';
import { AvatarModule } from 'primeng/avatar';
import { DropdownModule } from 'primeng/dropdown';
import { IMenu } from './interfaces/account.interface';
import { AccountService } from './services/account.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { forkJoin, Subscription } from 'rxjs';
import { IStripeInvoice } from '@libs/interfaces';
import { StripeService } from '@libs/services/stripe.service';
import { ApiSportsIoService } from '@libs/services/api-sports-io.service';
import {
  ApiSportsIoEndpoint,
  SportTeam,
} from '@libs/interfaces/api-sports-io.interface';
import { endpoints } from '@libs/constants/api-sports-io.constant';
import { LoadingService } from '@libs/services/loading.service';

@Component({
  selector: 'app-account',
  standalone: true,
  imports: [
    RouterOutlet,
    CommonModule,
    FormsModule,
    RouterLink,
    RouterLinkActive,
    AvatarModule,
    DropdownModule,
    TableModule,
    ButtonModule,
  ],
  templateUrl: './account.component.html',
  styleUrl: './account.component.scss',
})
export class AccountComponent implements OnInit, OnDestroy {
  getProfileSubscription: Subscription = new Subscription();
  getCustomerInvoicesSubscription: Subscription = new Subscription();
  maxContentWidthSubscription: Subscription = new Subscription();
  getSportTeamsSubscription: Subscription = new Subscription();
  getUserTravelProfilesSubscription: Subscription = new Subscription();
  getStripeSusbcriptionInvoicesSubscription: Subscription = new Subscription();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  userProfile: any;
  menus: IMenu[] = [];
  selectedMenu: IMenu = { label: 'My Details', path: '/account/my-details' };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  user: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  profilePictureUrl: any;
  invoices: IStripeInvoice[] = [];
  invoiceCurrency = 'USD';

  showHideInvoiceTableSubscription: Subscription = new Subscription();
  invoiceTableDisplay = false;
  maxContentWidth = '';

  sportEndpoints: ApiSportsIoEndpoint[] = endpoints;
  sportCountry = 'USA';

  constructor(
    private _accountService: AccountService,
    private _stripeService: StripeService,
    private _apiSportsIoService: ApiSportsIoService,
    private _loadingService: LoadingService,
    private _router: Router,
  ) {}

  ngOnInit(): void {
    this.menus = [
      { label: 'My Details', path: '/account/my-details' },
      { label: 'Travel Profile', path: '/account/travel-profile' },
      { label: 'Subscription', path: '/account/subscription' },
      { label: 'Payment Information', path: '/account/payment-information' },
      { label: 'Security', path: '/account/security' },
      { label: 'Feedback', path: '/account/feedback' },
      { label: 'Settings', path: '/account/settings' },
    ];

    const urlOnInit = this._router.url;
    const menuOnInit = this.menus.filter((menu) => menu.path === urlOnInit)[0];
    this.selectedMenu.label = menuOnInit?.label;
    this.selectedMenu.path = menuOnInit?.path;

    this.getProfileSubscription = this._accountService.getProfile().subscribe({
      next: (res) => {
        this.userProfile = res;

        if (this.userProfile.profilePicture !== null) {
          this._accountService
            .getProfilePictureUrl(this.userProfile.profilePicture)
            .subscribe({
              next: (res) => {
                this.profilePictureUrl = res.fileUrl;
              },
            });
        } else {
          this.profilePictureUrl = null;
        }
      },
    });

    this.showHideInvoiceTableSubscription =
      this._accountService.showHideInvoiceTable$.subscribe({
        next: (value) => {
          this.invoiceTableDisplay = value;
        },
      });

    this.getCustomerInvoicesSubscription = this._stripeService
      .getCustomerInvoices()
      .subscribe({
        next: (res) => {
          this.invoices = res.data;
        },
      });

    this.maxContentWidthSubscription =
      this._accountService.maxContentWidth$.subscribe({
        next: (value) => {
          if (value) {
            this.maxContentWidth = 'max-w-max';
          } else {
            this.maxContentWidth = '';
          }
        },
      });

    this.retrieveAllSports();
  }

  ngOnDestroy(): void {
    this.getProfileSubscription.unsubscribe();
    this.getCustomerInvoicesSubscription.unsubscribe();
    this.showHideInvoiceTableSubscription.unsubscribe();
  }

  /**
   * Retrieve all sport teams
   */
  retrieveAllSports() {
    this._loadingService.loadingOn();

    const sportApiCalls = [];
    for (const sport of this.sportEndpoints) {
      sportApiCalls.push(
        this._apiSportsIoService.getSportTeams(
          sport.version,
          sport.name,
          this.sportCountry,
        ),
      );
    }

    this.getSportTeamsSubscription = forkJoin(sportApiCalls).subscribe(
      (results) => {
        let teams: SportTeam[] = [];
        for (const res of results) {
          teams = [...teams, ...res];
        }
        this._accountService.setSportTeams(teams);
        this._loadingService.loadingOff();
      },
    );
  }

  /**
   * Navigate to selected route on dropdown menu changes
   * @param event Event object
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChangeDropdownMenu(event: any): void {
    const menu = this.menus.find((m) => m.path === event.value);
    if (menu) {
      this.selectedMenu.label = menu.label;
      this.selectedMenu.path = menu.path;
    }

    this._router.navigate([menu?.path]);
  }

  /**
   * Download invoice (using url provided by Stripe)
   * @param url
   * @param filename
   */
  downloadInvoicePdf(url: string, filename: string) {
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
}
