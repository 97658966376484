<app-support-header
  #supportHeader
  (onSearch)="onSearch($event)"
></app-support-header>
@if (isLoading) {
  <p-progressSpinner
    styleClass="w-4rem h-4rem"
    strokeWidth="8"
    fill="var(--surface-ground)"
    animationDuration=".5s"
    class="flex mt-5"
  />
} @else {
  <app-support-body
    [supports]="supports"
    [pages]="pages"
    [paginator]="paginator"
    (onPageChange)="changePage($event)"
  ></app-support-body>
}
<app-support-footer></app-support-footer>
