import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [RouterOutlet],
  template: '<div class="content-container"><router-outlet /></div>',
  styles: [],
})
export class HomeComponent {}
