<div class="support-header-background">
  <div class="support-header-frame">
    <div class="support-header-content">
      <h1>How can we help?</h1>
      <span
        >Find answers to your questions, access helpful resources, and get
        personalized assistance with our comprehensive support services.</span
      >
      <div class="support-search">
        <p-iconField iconPosition="left" styleClass="w-full">
          <p-inputIcon styleClass="pi pi-search" />
          <input
            type="text"
            id="id-support-search"
            pInputText
            placeholder="Ask away.."
            class="w-full"
            (keyup)="onChange($event)"
          />
        </p-iconField>
        <div class="support-popular">
          <span>POPULAR:</span>
          @for (item of listPopularSupport; track $index) {
            <a href="support/search?v={{ encode(item.value) }}">{{
              item.value
            }}</a>
          }
        </div>
      </div>
    </div>
  </div>
</div>
