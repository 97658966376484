<div class="flex w-screen h-screen m-0 relative">
  <!-- * Sidebar Desktop Version * -->
  <div class="hidden lg:block lg:col-fixed lg:left-sidebar top-0 h-full">
    <div class="flex flex-column pt-5 pb-2 h-full" style="padding-left: 13px">
      <!-- Sidebar header -->
      <div class="pb-8 ml-2">
        <div class="left-sidebar-logo"></div>
      </div>

      <!-- Sidebar body -->
      <div class="flex-grow-1 gap-5 overflow-hidden overflow-y-auto">
        <!-- parentRoute == menu.routerLink -->
        <div
          *ngFor="let menu of menuItems"
          class="flex mb-3 menu-item"
          [ngClass]="{
            'menu-item-active': parentRoute === menu.routerLink,
          }"
        >
          <button class="button-menu" [routerLink]="menu.routerLink">
            <img class="menu-item-icon" [src]="menu.icon" alt="Home" />
            <span class="menu-item-label">
              {{ menu.label }}
            </span>
          </button>
        </div>
      </div>

      <!-- Sidebar footer -->
      <div class="flex flex-column gap-3 mt-auto pt-3">
        <div class="flex align-items-end mt-auto menu-item">
          <button class="button-menu" routerLink="/support">
            <img
              class="menu-item-icon"
              src="/assets/icons/icon-shield-question-mark.svg"
              alt="Support"
            />
            <span class="menu-item-label"> Support </span>
          </button>
        </div>

        <hr class="menu-item-separator" />

        <div class="flex align-items-end mb-2 menu-item">
          <button class="button-menu" routerLink="/auth/login">
            <img
              class="menu-item-icon"
              src="/assets/icons/icon-sign-out.svg"
              alt="Log Out"
            />
            <span class="menu-item-label"> Log Out </span>
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- App Container -->
  <div class="flex-1 p-0 overflow-auto lg:main-content-container">
    <!-- Topbar -->
    <div class="flex topbar">
      <div
        class="flex align-items-center flex-wrap h-auto w-full container sticky top-0"
      >
        <!-- Left side topbar -->
        <div class="left-topbar flex-1">
          <!-- SHow on mobile and tablet screen only -->
          <p-button
            (onClick)="sidebarVisible = true"
            [outlined]="false"
            [text]="true"
            class="lg:hidden"
            styleClass="focus:border-none"
          >
            <img src="/assets/icons/icon-menu.svg" alt="Menu" />
          </p-button>

          <!-- SHow on desktop screen only -->
          <p-iconField iconPosition="left" class="hidden lg:block w-full ml-6">
            <p-inputIcon styleClass="pi pi-search" />
            <input
              type="text"
              pInputText
              placeholder="Search"
              class="w-10"
              style="color: #667085bf"
            />
          </p-iconField>
        </div>

        <!-- Right side top bar -->
        <div
          class="right-topbar flex flex-1 align-self-center align-items-center justify-content-end"
        >
          <button
            class="button-plain px-2 mx-2"
            (click)="toggleNotification($event)"
          >
            <div
              *ngIf="unreadNotificationCount > 0"
              class="notification-unread-badge"
            >
              {{ unreadNotificationDisplay }}
            </div>
            <img src="/assets/icons/icon-notification.svg" alt="Notification" />
          </button>
          <p-overlayPanel #op styleClass="notification-panel">
            <div class="w-25rem h-30rem overflow-y-auto overflow-x-hidden">
              <div class="header">
                <button
                  class="md:hidden button-plain absolute"
                  (click)="op.hide()"
                >
                  <img src="/assets/icons/icon-arrow-back.svg" alt="Close" />
                </button>
                <h5 class="text-center md:text-left">Notifications</h5>
              </div>
              @for (notification of notifications; track $index) {
                <hr style="padding: -75px" />
                <div class="flex gap-2">
                  <div class="bullet-{{ notification.severity }}"></div>
                  <div class="text-xs w-full flex flex-column">
                    <span
                      class="font-medium"
                      [innerHTML]="notification.message"
                    ></span>
                    <span class="text-xss text-color-light">{{
                      notification.time | timeAgo
                    }}</span>
                  </div>
                </div>
              }
            </div>
          </p-overlayPanel>
          <button
            class="button-plain px-2 mx-2 hidden lg:block"
            routerLink="/account/my-details"
          >
            <div
              class="flex gap-2 cursor-pointer no-underline profile-detail-menu"
            >
              <p-avatar
                [image]="profilePictureUrl"
                styleClass="bg-green-100"
                size="large"
                shape="circle"
              />
              <div
                class="flex flex-column justify-content-center vertical-align-middle profile-detail-menu text-left"
              >
                <span class="text-sm font-semibold">{{ userFullName }}</span>
                <span class="text-xs font-normal">{{ userEmail }}</span>
              </div>
            </div>
          </button>
        </div>
      </div>
    </div>

    <div class="p-0">
      <router-outlet />
    </div>
  </div>
</div>

<p-sidebar
  [(visible)]="sidebarVisible"
  [showCloseIcon]="false"
  styleClass="sidebar-container lg:hidden w-screen"
>
  <ng-template pTemplate="header">
    <p-button
      icon="pi pi-angle-left"
      (onClick)="sidebarVisible = false"
      [outlined]="false"
      [text]="true"
      styleClass="focus:border-none sidebar-close-btn text-white"
    >
    </p-button>
  </ng-template>

  <ng-template pTemplate="content">
    <div class="flex flex-column gap-2 h-full mx-2">
      <!-- Sidebar content header -->
      <div class="flex flex-column gap-3">
        <div class="flex flex-row align-items-center justify-content-start">
          <a
            routerLink="/account/my-details"
            class="flex cursor-pointer no-underline profile-detail-menu"
            (click)="sidebarVisible = false"
          >
            <p-avatar
              [image]="profilePictureUrl"
              styleClass="mr-2"
              size="large"
              shape="circle"
            />
            <div class="inline">
              <span class="block text-white text-sm font-semibold">
                {{ userFullName }}
              </span>
              <span class="text-white text-xs font-normal">
                {{ userEmail }}
              </span>
            </div>
          </a>
        </div>
        <div>
          <p-iconField iconPosition="left" class="w-full">
            <p-inputIcon styleClass="pi pi-search" />
            <input type="text" pInputText placeholder="Search" class="w-full" />
          </p-iconField>
        </div>
      </div>

      <!-- Sidebar content body -->
      <div class="flex-grow-1 flex flex-column overflow-auto">
        <div
          *ngFor="let menu of menuItems"
          class="flex mt-2 mb-2 w-full sidebar-menu-item"
          [ngClass]="{
            'menu-item-active': parentRoute === menu.routerLink,
          }"
        >
          <button
            class="button-menu text-white"
            [routerLink]="menu.routerLink"
            (click)="sidebarVisible = false"
          >
            <img
              class="menu-item-icon mr-3"
              [src]="menu.icon"
              [alt]="menu.label"
            />
            <span class="sidebar-menu-item-label"> {{ menu.label }} </span>
          </button>
        </div>
      </div>

      <!-- Sidebar content footer -->
      <div class="flex flex-column">
        <div class="flex w-full mb-3 sidebar-menu-item">
          <button
            class="button-menu text-white"
            (click)="sidebarVisible = false"
            routerLink="/support"
          >
            <img
              class="menu-item-icon mr-3"
              src="/assets/icons/icon-shield-question-mark.svg"
              alt="Support"
            />
            <span class="sidebar-menu-item-label"> Support </span>
          </button>
        </div>

        <hr class="sidebar-menu-item-separator" />

        <div class="flex w-full mt-2 sidebar-menu-item menu-item">
          <button
            class="button-menu text-white"
            routerLink="/auth/login"
            (click)="sidebarVisible = false"
          >
            <img
              class="menu-item-icon mr-3"
              src="/assets/icons/icon-sign-out.svg"
              alt="Log Out"
            />
            <span class="sidebar-menu-item-label"> Log Out </span>
          </button>
        </div>
      </div>
    </div>
  </ng-template>
</p-sidebar>

<div
  *ngIf="loading$"
  class="absolute top-0 h-full w-full flex justify-content-center align-items-center surface-700 opacity-40"
>
  <p-progressSpinner ariaLabel="loading" />
</div>
