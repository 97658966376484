import { Routes } from '@angular/router';
import { LayoutAuthComponent } from './layouts/layout-auth/layout-auth.component';
import { LayoutPrivateComponent } from './layouts/layout-private/layout-private.component';
import { authGuard, noAuthGuard } from '@libs/guards';
import { AccountComponent } from '@modules/account/account.component';
import { twoFAAuthGuard } from '@libs/guards/two-fa.guard';
import { HomeComponent } from '@modules/home/home.component';
import { MyTicketsComponent } from '@modules/my-tickets/my-tickets.component';
import { FlightHistoryComponent } from '@modules/flight-history/flight-history.component';
import { ItineraryComponent } from '@modules/my-itinerary/pages/itinerary/itinerary.component';
import { DiscoverComponent } from '@modules/discover/discover.component';
import { SupportComponent } from '@modules/support/support.component';
import { FlightComponent } from '@modules/flight/flight.component';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'home',
  },
  {
    path: 'auth',
    component: LayoutAuthComponent,
    canActivate: [noAuthGuard],
    loadChildren: () =>
      import('./modules/auth/auth.routes').then((m) => m.authRoutes),
  },
  {
    path: 'auth/2fa',
    component: LayoutAuthComponent,
    canActivate: [twoFAAuthGuard],
    loadChildren: () =>
      import('./modules/auth/auth.routes').then((m) => m.twoFARoutes),
  },
  {
    path: 'auth/2fa',
    component: LayoutAuthComponent,
    canActivate: [authGuard],
    loadChildren: () =>
      import('./modules/auth/auth.routes').then((m) => m.twoFASkippableRoutes),
  },
  {
    path: '',
    component: LayoutPrivateComponent,
    canActivate: [authGuard],
    children: [
      {
        path: 'account',
        component: AccountComponent,
        canActivate: [authGuard],
        data: {
          title: 'Account & Profile',
        },
        loadChildren: () =>
          import('./modules/account/account.routes').then(
            (m) => m.accountRoutes,
          ),
      },
      {
        path: 'home',
        component: HomeComponent,
        canActivate: [],
        data: {
          title: 'Home',
        },
        loadChildren: () =>
          import('./modules/home/home.routes').then((m) => m.homeRoutes),
      },
      {
        path: 'flight',
        component: FlightComponent,
        canActivate: [],
        data: {
          title: 'Flight',
        },
        loadChildren: () =>
          import('./modules/flight/flight.routes').then((m) => m.flightRoutes),
      },
      {
        path: 'my-ticket',
        component: MyTicketsComponent,
        canActivate: [],
        data: {
          title: 'My Tickets',
        },
        loadChildren: () =>
          import('./modules/my-tickets/my-tickets.routes').then(
            (m) => m.myTicketsRoutes,
          ),
      },
      {
        path: 'my-itinerary',
        component: ItineraryComponent,
        canActivate: [],
        data: {
          title: 'My Itinerary',
        },
        loadChildren: () =>
          import('./modules/my-itinerary/my-itinerary.routes').then(
            (m) => m.myItineraryRoutes,
          ),
      },
      {
        path: 'history',
        component: FlightHistoryComponent,
        canActivate: [],
        data: {
          title: 'History',
        },
      },
      {
        path: 'discover',
        component: DiscoverComponent,
        canActivate: [],
        data: {
          title: 'Discover',
        },
        loadChildren: () =>
          import('./modules/discover/discover.routes').then(
            (m) => m.discoverRoutes,
          ),
      },
      {
        path: 'support',
        component: SupportComponent,
        canActivate: [],
        data: {
          title: 'support',
        },
      },
    ],
  },
];
