import { Component, Input } from '@angular/core';
import { TrimHtmlTagPipe, TruncatePipe } from '@libs/pipes';
import { TrimHubspotBlogTagPipe } from '@libs/pipes/trim-hubspot-blog-tag.pipe';

@Component({
  selector: 'app-support-card',
  standalone: true,
  imports: [TrimHtmlTagPipe, TruncatePipe, TrimHubspotBlogTagPipe],
  templateUrl: './support-card.component.html',
  styleUrl: './support-card.component.scss',
})
export class SupportCardComponent {
  @Input({ required: true }) title!: string;
  @Input({ required: true }) body!: string;
  @Input({ required: true }) url!: string;

  openBlog(): void {
    window.open(this.url, '_blank');
  }
}
