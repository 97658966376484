import { Component, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterOutlet,
} from '@angular/router';
import { SplashScreenCarouselComponent } from '@libs/components';
import { ICarouselContent } from '@libs/interfaces';
import { SplashScreenService } from '@libs/services';
import { filter, Subscription } from 'rxjs';
import { AuthService } from '@modules/auth/services/auth.service'
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'app-layout-auth',
  standalone: true,
  imports: [RouterOutlet, SplashScreenCarouselComponent, ButtonModule],
  templateUrl: './layout-auth.component.html',
  styleUrl: './layout-auth.component.scss',
})
export class LayoutAuthComponent implements OnDestroy, OnInit {
  private _routeSubscription!: Subscription;
  isShowSplashScreen = false;
  carouselContents!: ICarouselContent[];
  isShowSkipButton = false;
  skipButtonSubscription!: Subscription;
  skipNextNavigate = '';

  constructor(
    private _activeRoute: ActivatedRoute,
    private _router: Router,
    private _splashScreenService: SplashScreenService,
    private _authService: AuthService,
    private _cd: ChangeDetectorRef,
  ) {
    // * Subscribe router
    this._routerSubscriber();
  }

  ngOnInit(): void {
    this.skipButtonSubscription = this._authService.showHideSkipButtonOnSetup$.subscribe({
      next: (value) => {
        this.isShowSkipButton = value.cond;
        this.skipNextNavigate = value.navigate;
        this._cd.detectChanges();
      },
    });
  }

  ngOnDestroy(): void {
    if (this.isShowSplashScreen) this._routeSubscription.unsubscribe();
    this.skipButtonSubscription?.unsubscribe();
  }

  /**
   * @description Stream router for get page title every page changes
   */
  private _routerSubscriber() {
    this._routeSubscription = this._router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.isShowSplashScreen =
          !!this._activeRoute.snapshot.firstChild?.data['isShowSplashScreen'];

        if (this.isShowSplashScreen && !this.carouselContents?.length)
          this._getSplashScreen();
      });
  }

  /**
   * @description Get all splash screen data from backend
   */
  private _getSplashScreen(): void {
    this._splashScreenService.getAll().subscribe((splashScreens) => {
      this.carouselContents = splashScreens.map((splashScreen) => {
        return { text: splashScreen.text, media: splashScreen.image };
      });
    });
  }

  Skip(): void {
    this._router.navigate([this.skipNextNavigate]);
  }
}
