import { provideHttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import {
  PreloadAllModules,
  RouterOutlet,
  provideRouter,
  withDebugTracing,
  withPreloading,
} from '@angular/router';
import { routes } from './app.routes';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { NumberToWordsPipe } from '@libs/pipes/number-to-words.pipe';
import { AirportCityPipe } from '@libs/pipes/airport-city.pipe';
import { DateRangeDurationPipe } from '@libs/pipes';
import { DateAirportLocalePipe } from '@libs/pipes/date-airport-locale.pipe';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, ToastModule],
  template: `<router-outlet /> <p-toast />`,
  styles: [],
  providers: [
    DatePipe,
    CurrencyPipe,
    NumberToWordsPipe,
    AirportCityPipe,
    DateRangeDurationPipe,
    DateAirportLocalePipe,
  ],
})
export class AppComponent {}

bootstrapApplication(AppComponent, {
  providers: [
    MessageService,
    provideHttpClient(),
    provideRouter(
      routes,
      withPreloading(PreloadAllModules),
      withDebugTracing(),
    ),
  ],
});
