import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  IHubspotBlog,
  IPaginationParams,
  IPaginationResponse,
} from '@libs/interfaces';
import { Observable } from 'rxjs';
import { SUPPORT_API } from '../constants/support-service.constant';

@Injectable({
  providedIn: 'root',
})
export class SupportService {
  constructor(private readonly _http: HttpClient) {}

  findAll(
    params: IPaginationParams,
  ): Observable<IPaginationResponse<IHubspotBlog>> {
    return this._http.get<IPaginationResponse<IHubspotBlog>>(
      SUPPORT_API.GET_ALL,
      {
        params: { ...params },
      },
    );
  }
}
