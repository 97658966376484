import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  RouteConfigLoadEnd,
  RouteConfigLoadStart,
  Router,
  RouterModule,
  RouterOutlet,
} from '@angular/router';
import { SidebarModule } from 'primeng/sidebar';
import { BadgeModule } from 'primeng/badge';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ButtonModule } from 'primeng/button';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { InputTextModule } from 'primeng/inputtext';
import { AvatarModule } from 'primeng/avatar';
import { MenuItem } from 'primeng/api';
import { AccountService } from '@modules/account/services/account.service';
import { MENU_PRIVATE } from '@libs/constants';
import { filter, Subscription, tap } from 'rxjs';
import { LoadingService } from '@libs/services/loading.service';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';
import { TimeAgoPipe } from '@libs/pipes';
import { NotificationService } from '@libs/services/notification.service';
import {
  getToken,
  MessagePayload,
  Messaging,
  onMessage,
} from '@angular/fire/messaging';
import { INotification } from '@libs/interfaces';
import { environment } from 'environments/environment';
import { ItineraryEventService } from '@modules/my-itinerary/services/itinerary-event.service';
import { GoogleCalendarService } from '@libs/services/google-calendar.service';

@Component({
  selector: 'app-layout-private',
  standalone: true,
  imports: [
    RouterOutlet,
    RouterModule,
    CommonModule,
    SidebarModule,
    ButtonModule,
    InputTextModule,
    IconFieldModule,
    InputIconModule,
    BadgeModule,
    AvatarModule,
    ProgressSpinnerModule,
    OverlayPanelModule,
    TimeAgoPipe,
  ],
  templateUrl: './layout-private.component.html',
  styleUrl: './layout-private.component.scss',
})
export class LayoutPrivateComponent implements OnInit, OnDestroy {
  private _messaging: Messaging = inject(Messaging);
  private _broadcastchannel: BroadcastChannel | undefined;

  menuItems: MenuItem[] = MENU_PRIVATE;
  parentRoute!: string;
  profilePictureUrl = '';
  sidebarVisible = false;
  userEmail!: string;
  userFullName!: string;
  @ViewChild('op', { static: true }) notificationPanel:
    | OverlayPanel
    | undefined;
  firebaseNotification: MessagePayload | undefined;
  notifications: INotification[] = [];
  unreadNotificationCount: number = 0;
  unreadNotificationDisplay: string = '0';

  private _loadingSubscription!: Subscription;
  private _routeSubscription!: Subscription;
  loading$ = false;

  @Input()
  detectRouteTransitions = false;

  constructor(
    private _accountService: AccountService,
    private _router: Router,
    private _ar: ActivatedRoute,
    private _loadingService: LoadingService,
    private _notificationService: NotificationService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _itineraryEventService: ItineraryEventService,
    private _googleCalendarService: GoogleCalendarService,
  ) {
    this._routerSubscriber();
  }

  ngOnInit(): void {
    const googleCalendarCode = this._ar.snapshot.queryParamMap.get('code');
    if (googleCalendarCode) {
      this._googleCalendarService
        .getSetAccessToken(googleCalendarCode)
        .subscribe();
    }

    this._requestPermission();
    this._listen();

    this._broadcastchannel = new BroadcastChannel('background-notification');
    this._broadcastchannel.onmessage = (evt: MessageEvent) => {
      this._notificationService.addFirebaseNotification(evt.data);
    };

    this._loadingSubscription = this._loadingService.loading$.subscribe({
      next: (value) => {
        this.loading$ = value;
      },
    });

    if (this.detectRouteTransitions) {
      this._router.events.pipe(
        tap((event) => {
          if (event instanceof RouteConfigLoadStart) {
            this._loadingService.loadingOn();
          } else if (event instanceof RouteConfigLoadEnd) {
            this._loadingService.loadingOff();
          }
        }),
      );
    }

    this._accountService.getProfile().subscribe({
      next: (res) => {
        this.userFullName = `${res.firstName ?? ''} ${res.lastName ?? ''}`;
        this.userEmail = res.email ?? '';

        if (res.profilePicture)
          this._accountService
            .getProfilePictureUrl(res.profilePicture)
            .subscribe({
              next: (res) => {
                this.profilePictureUrl = res.fileUrl;
              },
            });
      },
    });

    this._notificationService.getUserNotifications().subscribe({
      next: (res) => {
        for (let notification of res) {
          notification.time = notification.startTimestamp
            ? new Date(notification.startTimestamp).getTime()
            : new Date().getTime();
          notification.severity = notification.type
            ? notification.type
            : 'primary';
        }

        this.notifications = [...this.notifications, ...res].sort((a, b) => {
          return b.time - a.time;
        });

        this._notificationService.setUnreadNotificationCount(
          this.notifications.filter((n) => n.status === 'unread').length,
        );
      },
    });

    this._notificationService.firebaseNotifications$.subscribe({
      next: (res) => {
        this.firebaseNotification = res;

        if (this.firebaseNotification?.data) {
          const newNotification: INotification = {
            time: new Date(
              this.firebaseNotification?.data['startTimestamp'],
            ).getTime(),
            ...this.firebaseNotification.data,
          };

          this.notifications = [
            ...this.notifications,
            ...[newNotification],
          ].sort((a, b) => {
            return b.time - a.time;
          });

          this._notificationService.setUnreadNotificationCount(
            this.notifications.filter((n) => n.status === 'unread').length,
          );
        }
      },
    });

    this._notificationService.unreadNotificationsCount$.subscribe({
      next: (res) => {
        this.unreadNotificationCount = res;
        this._changeDetectorRef.detectChanges();
        this.setNotificationUnreadDisplay();
      },
    });
  }

  ngOnDestroy(): void {
    this._loadingSubscription.unsubscribe();
    this._routeSubscription.unsubscribe();
  }

  toggleNotification(event: Event) {
    this.notificationPanel?.toggle(event);

    // mark all notification as read 3 seconds after user toggle the dropdown
    setTimeout(() => {
      this._notificationService.markUserNotificationsAsRead().subscribe({
        next: () => {
          for (let notification of this.notifications) {
            notification.status = 'read';
          }

          this._notificationService.setUnreadNotificationCount(
            this.notifications.filter((n) => n.status === 'unread').length,
          );
        },
      });
    }, 3000);
  }

  setNotificationUnreadDisplay() {
    this.unreadNotificationDisplay = `${this.unreadNotificationCount}`;
    if (this.unreadNotificationCount > 10) {
      this.unreadNotificationDisplay = '10+';
    }
  }

  /**
   * @description Stream router for get page title every page changes
   */
  private _routerSubscriber() {
    this._routeSubscription = this._router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.parentRoute = `/${this._router.url.split('/')[1]}`;
        this._getIsShowItineraryPage();
      });
  }

  private _requestPermission() {
    getToken(this._messaging, {
      vapidKey: environment.FIREBASE_VAPID_KEY,
    }).then((currentToken) => {
      if (currentToken) {
        this._notificationService.registerDevice(currentToken).subscribe();
      }
    });
  }

  private _listen() {
    onMessage(this._messaging, (payload) => {
      this._notificationService.addFirebaseNotification(payload);
    });
  }

  private _getIsShowItineraryPage() {
    this._itineraryEventService.getIsShowPage().subscribe({
      next: (isShow) => {
        if (isShow) this.menuItems = MENU_PRIVATE;
        else
          this.menuItems = MENU_PRIVATE.filter(
            (item) => item.routerLink !== '/my-itinerary',
          );
      },
    });
  }
}
