import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SportTeam } from '@libs/interfaces/api-sports-io.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiSportsIoService {
  _baseUrl = 'api-sports-io';

  constructor(private _http: HttpClient) {}

  /**
   * Get sport teams by sport and country
   * @param sport
   * @param country
   * @returns
   */
  getSportTeams(
    version: string,
    sport: string,
    country: string,
  ): Observable<SportTeam[]> {
    return this._http.get<SportTeam[]>(
      `${this._baseUrl}/get-sport-teams/${version}/${sport}/${country}`,
    );
  }
}
