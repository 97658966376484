import { ApiSportsIoEndpoint } from '@libs/interfaces/api-sports-io.interface';

export const endpoints: ApiSportsIoEndpoint[] = [
  { version: 'v1', name: 'afl' },
  { version: 'v1', name: 'baseball' },
  { version: 'v1', name: 'basketball' },
  { version: 'v3', name: 'football' },
  { version: 'v1', name: 'formula-1' },
  { version: 'v1', name: 'handball' },
  { version: 'v1', name: 'hockey' },
  { version: 'v1', name: 'mma' },
  { version: 'v2', name: 'nba' },
  { version: 'v1', name: 'american-football' },
  { version: 'v1', name: 'rugby' },
  { version: 'v1', name: 'volleyball' },
];
