import { Component, OnInit } from '@angular/core';
import { SupportHeaderComponent } from './components/support-header/support-header.component';
import { SupportBodyComponent } from './components/support-body/support-body.component';
import { SupportFooterComponent } from './components/support-footer/support-footer.component';
import { IPopularSupport, ISupportPagePagination } from './interfaces';
import { BehaviorSubject } from 'rxjs';
import { IHubspotBlog, IPagination, IPaginationParams } from '@libs/interfaces';
import { defaultPagination } from '@libs/constants';
import { SupportService } from './services/support.service';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

@Component({
  selector: 'app-support',
  standalone: true,
  imports: [
    SupportHeaderComponent,
    SupportBodyComponent,
    SupportFooterComponent,
    ProgressSpinnerModule,
  ],
  templateUrl: './support.component.html',
  styleUrl: './support.component.scss',
})
export class SupportComponent implements OnInit {
  isLoading = false;
  pages: string[] = ['0'];
  paginator: IPagination = { page: 0, limit: 9, totalItems: 0 };
  search?: string;
  supports?: IHubspotBlog[];

  private readonly _getOptionParams = new BehaviorSubject<IPaginationParams>({
    ...defaultPagination,
    limit: 9,
  });

  listPopularSearch: IPopularSupport[] = [
    {
      value: 'Lorem sit amet?',
    },
    {
      value: 'ipsum dolor sit amet?',
    },
  ];

  constructor(private readonly _supportService: SupportService) {}

  ngOnInit(): void {
    this._getAll();
  }

  changePage(event: ISupportPagePagination): void {
    this._getOptionParams.next({
      page: event.page,
      search: this.search ?? '',
      limit: 9,
    });
  }

  onSearch(event: string): void {
    this.search = event;
    this.paginator.page = 0;
    this._getOptionParams.next({
      page: 0,
      search: event,
      limit: 9,
    });
  }

  /**
   * @description Get blog list from the api
   */
  private _getAll(): void {
    this._getOptionParams.subscribe((params: IPaginationParams) => {
      this.isLoading = true;
      this._supportService.findAll(params).subscribe({
        next: (res) => {
          this.supports = res.items;
          this.paginator.totalItems = res.total;
          this.paginator.nextPage = res.page;
          if (!this.pages.includes(res.page)) this.pages.push(res.page ?? '');
        },
        error: () => {
          this.isLoading = false;
        },
        complete: () => {
          this.isLoading = false;
        },
      });
    });
  }
}
