import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SupportCardComponent } from '../support-card/support-card.component';
import { IHubspotBlog, IPagination } from '@libs/interfaces';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { ISupportPagePagination } from '@modules/support/interfaces';

@Component({
  selector: 'app-support-body',
  standalone: true,
  imports: [CommonModule, SupportCardComponent, ButtonModule],
  templateUrl: './support-body.component.html',
  styleUrl: './support-body.component.scss',
})
export class SupportBodyComponent {
  @Input({ required: true }) supports?: IHubspotBlog[];
  @Input({ required: true }) pages: string[] = ['0'];
  @Input({ required: true }) paginator!: IPagination;

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onPageChange = new EventEmitter<ISupportPagePagination>();

  /**
   * @description Next page content
   */
  prevPage(): void {
    this.paginator.page--;
    this.onPageChange.emit({
      page: this.pages[this.paginator.page],
      type: 'previous',
    });
  }

  /**
   * @description Prev page content
   */
  nextPage(): void {
    this.paginator.page++;
    this.onPageChange.emit({
      page: this.pages[this.paginator.page],
      type: 'next',
    });
  }
}
