import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'app-support-footer',
  standalone: true,
  imports: [ButtonModule],
  templateUrl: './support-footer.component.html',
  styleUrl: './support-footer.component.scss',
})
export class SupportFooterComponent {
  constructor(private _router: Router) {}
}
