<div class="content-container">
  <div class="grid">
    <div class="col-12 mt-4">
      <app-card cardClass="h-auto">
        <div body>
          <div>
            <p-table
              #ticketTable
              [value]="data"
              [loading]="loading"
              [globalFilterFields]="[
                'flightNumber',
                'departureCity',
                'arrivalCity',
                'aircraft',
                'duration',
                'departureDateLocale',
                'departureTimeLocale',
                'arrivalDateLocale',
                'arrivalTimeLocale',
              ]"
              [tableStyle]="{ 'min-width': '50rem' }"
              [rows]="10"
              [scrollable]="true"
              scrollHeight="338px"
            >
              <ng-template pTemplate="caption">
                <div
                  class="flex flex-column justify-content-start lg:flex-row align-items-start lg:align-items-center mb-4"
                >
                  <span class="text-xl font-semibold mb-2 lg:mb-0"
                    >History</span
                  >
                  <p-iconField
                    iconPosition="left"
                    class="mb-2 lg:ml-auto lg:mb-0 global-search lg:global-search"
                  >
                    <p-inputIcon>
                      <i class="pi pi-search"></i>
                    </p-inputIcon>
                    <input
                      pInputText
                      type="text"
                      (input)="
                        ticketTable.filterGlobal(
                          $any($event.target).value,
                          'contains'
                        )
                      "
                      placeholder="Search"
                      class="global-search lg:global-search"
                    />
                  </p-iconField>
                </div>
              </ng-template>
              <ng-template pTemplate="header">
                <tr class="prepade-table-header">
                  <th>Flight Number</th>
                  <th>From</th>
                  <th>To</th>
                  <th>Aircraft Type</th>
                  <th>Baggage</th>
                  <th>Duration</th>
                  <th>Departure</th>
                  <th>Arrival</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-ticket>
                <tr class="prepade-table-row">
                  <td>{{ ticket.flightNumber }}</td>
                  <td>{{ ticket.departureCity }}</td>
                  <td>{{ ticket.arrivalCity }}</td>
                  <td>{{ ticket.aircraft }}</td>
                  <td>-</td>
                  <td>
                    {{ ticket.duration }}
                  </td>
                  <td>
                    {{ ticket.departureDateLocale }}
                    <br />
                    {{ ticket.departureTimeLocale }}
                  </td>
                  <td>
                    {{ ticket.arrivalDateLocale }}
                    <br />
                    {{ ticket.arrivalTimeLocale }}
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="8" class="text-center">
                    There is no flight history at this time.
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </app-card>
    </div>
  </div>
</div>
