<div class="support-footer-background">
  <div class="support-footer-content">
    <h2>Still have questions? We’re here to help!</h2>
    <div class="flex flex-row justify-content-between align-items-end">
      <span>Chat with our support team by clicking the chat button. </span>
      <p-button
        severity="success"
        styleClass="p-button-success support-chat-button block-inline vertical-align-middle"
      >
        <div class="flex flex-row justify-content-between">
          <img src="/assets/icons/icon-chat-bubble.svg" alt="Back" />
          <span class="block-inline">Chat Support</span>
        </div>
      </p-button>
    </div>
  </div>
</div>
