import {
  HttpEvent,
  HttpInterceptorFn,
  HttpResponse,
} from '@angular/common/http';
import { map } from 'rxjs';

/**
 * Interceptor for mapping response data
 */
export const mapApiResponseInterceptor: HttpInterceptorFn = (req, next) => {
  return next(req).pipe(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    map((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        // * return response body data
        event = event.clone({ body: event.body.data });
      }
      return event;
    }),
  );
};
