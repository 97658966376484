import { AuthService } from '@modules/auth/services/auth.service'
import { environment } from 'environments/environment';

  //eslint-disable-next-line @typescript-eslint/no-unused-vars
export function facebookInitializer(authService: AuthService) {
  return () =>
    new Promise<void>((resolve) => {
      // wait for facebook sdk to initialize before starting the angular app
      window['fbAsyncInit'] = function () {

        FB.init({
          appId: environment.FACEBOOK_APP_ID,
          cookie: true,
          xfbml: true,
          version: 'v8.0',
        });

        FB.getLoginStatus(({ authResponse }) => {
          if (authResponse) {
						//todo
          } else {
            resolve();
          }
        });
      };

      // load facebook sdk script
      (function (d, s, id) {
        const fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          return;
        }
        const js = d.createElement(s) as HTMLScriptElement;
        js.id = id;
        js.src = 'https://connect.facebook.net/en_US/sdk.js';
        fjs.parentNode?.insertBefore(js, fjs);
      })(document, 'script', 'facebook-jssdk');
    });
}
