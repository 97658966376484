import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MessagePayload } from '@angular/fire/messaging';
import { INotification } from '@libs/interfaces';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private _baseUrl = 'notification';

  private _firebaseNotifications: BehaviorSubject<MessagePayload | undefined> =
    new BehaviorSubject<MessagePayload | undefined>(undefined);
  firebaseNotifications$ = this._firebaseNotifications.asObservable();
  private _unreadNotificationsCount: BehaviorSubject<number> =
    new BehaviorSubject<number>(0);
  unreadNotificationsCount$ = this._unreadNotificationsCount.asObservable();

  constructor(private _http: HttpClient) {}

  addFirebaseNotification(value: MessagePayload) {
    this._firebaseNotifications.next(value);
  }

  setUnreadNotificationCount(value: number) {
    this._unreadNotificationsCount.next(value);
  }

  getUserNotifications(): Observable<INotification[]> {
    return this._http.get<INotification[]>(
      `${this._baseUrl}/user-notifications`,
    );
  }

  markUserNotificationsAsRead(): Observable<INotification[]> {
    return this._http.get<INotification[]>(
      `${this._baseUrl}/mark-notifications-as-read`,
    );
  }

  registerDevice(token: string): Observable<any> {
    return this._http.post(`${this._baseUrl}/register-device`, {
      token: token,
    });
  }

  subscribeToTopic(token: string, topic: string): Observable<any> {
    return this._http.post(`${this._baseUrl}/subscribe-to-topic`, {
      token: token,
      topic: topic,
    });
  }
}
