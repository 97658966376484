import { HttpInterceptorFn } from '@angular/common/http';
import { environment } from 'environments/environment';

/**
 * Interceptor for set base API url
 */
export const baseApiUrlInterceptor: HttpInterceptorFn = (req, next) => {
  const newReq = req.clone({
    url: `${environment.BASE_API_URL}${req.url}`,
  });
  return next(newReq);
};
