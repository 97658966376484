<div class="support-body-frame">
  <div class="support-body-content">
    @for (item of supports; track $index) {
      <app-support-card
        [title]="item.htmlTitle"
        [body]="item.postSummary"
        [url]="item.url"
      />
    }
  </div>
  <div class="flex flex justify-content-center align-items-center gap-2 mt-3">
    <button
      pButton
      [text]="true"
      [disabled]="pages[paginator.page] === '0'"
      (click)="prevPage()"
      class="p-button-secondary flex align-items-center gap-1"
    >
      <i class="pi pi-angle-left text-3xl"></i>
      Prev
    </button>
    <div class="page-number-indicator">
      {{ paginator.page + 1 }}
    </div>
    <button
      pButton
      [text]="true"
      [disabled]="!pages[paginator.page + 1]"
      (click)="nextPage()"
      class="p-button-secondary flex align-items-center gap-1"
    >
      Next
      <i class="pi pi-angle-right text-3xl"></i>
    </button>
  </div>
</div>
