<div class="flex flex-column md:flex-row flex-wrap h-screen">
  <div
    class="flex-1 h-screen flex flex-column align-content-center flex-wrap overflow-x-auto"
  >
    <div class="main-content w-11 max-w-25rem mb-8">
      <div
        class="flex flex-row justify-content-between align-items-end flex-wrap"
      >
        <img
          src="/assets/images/prepade-logo.svg"
          alt="Prepade"
          class="logo mb-6"
        />
        @if (isShowSkipButton) {
          <button
            pButton
            type="button"
            label="Skip"
            class="p-button-link flex text-color justify-content-start mb-6 lg:translate-x-100"
            (click)="Skip()"
          >
            &NewLine;
          </button>
        }
      </div>
      <router-outlet />
    </div>
  </div>
  <div class="hidden lg:block md:flex-1 auth-background">
    @if (isShowSplashScreen) {
      <app-splash-screen-carousel [contents]="carouselContents" />
    }
  </div>

  <div
    class="bg-white fixed bottom-0 w-full text-center lg:text-left lg:w-6 py-2 lg:py-4 px-8 z-2"
  >
    &copy; 2024 All Rights Reserved.
  </div>
</div>
