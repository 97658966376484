import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '@modules/auth/services/auth.service';

/**
 * Guard for validate is authenticated or not, if authenticated will navigate to dashboard
 * @returns {boolean} true if unauthenticated and false if authenticated
 */
export const twoFAAuthGuard: CanActivateFn = () => {
  const authService = inject(AuthService);
  const router = inject(Router);

  if (!authService.isAuthenticated()) {
    router.navigate(['/auth/login']);
    return false;
  } else if (authService.hasUserTwoFAVerified()) {
    router.navigate(['']);
    return false;
  }
  return true;
};
