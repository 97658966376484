<div class="col-12 p-0">
  <div
    class="account-background sm:account-background md:account-background lg:account-background"
  ></div>

  <div
    class="account-header-content md:account-header-content lg:account-header-content xl:account-header-content"
  >
    <div
      class="grid account-user-info sm:account-user-info md:account-user-info lg:account-user-info xl:account-user-info"
    >
      <div
        class="col-12 sm:col-12 md:col-12 lg:col-2 account-avatar sm:account-avatar"
      >
        <p-avatar
          [image]="profilePictureUrl"
          styleClass="mr-2 avatar-xxl avatar-border-white"
          size="xlarge"
          shape="circle"
        />
      </div>
      <div
        class="col-12 sm:col-12 md:col-12 lg:col-10 account-user sm:account-user md:account-user lg:account-user xl:account-user"
      >
        <span class="user-fn"
          >{{
            userProfile !== undefined && userProfile.firstName !== null
              ? userProfile.firstName
              : ''
          }}
          {{
            userProfile !== undefined && userProfile.lastName !== null
              ? userProfile.lastName
              : ''
          }}</span
        >
        <span class="user-m block">{{
          userProfile !== undefined && userProfile.email !== null
            ? userProfile.email
            : '-'
        }}</span>
      </div>
    </div>
  </div>

  <div class="lg:hidden xl:hidden mt-2 account-menu-content">
    <span class="block mb-2 account-page-title">Account & Profile</span>
    <p-dropdown
      [options]="menus"
      optionLabel="label"
      optionValue="path"
      [style]="{ width: '100%' }"
      name="selectedMenu"
      [(ngModel)]="selectedMenu.path"
      (onChange)="onChangeDropdownMenu($event)"
    />
  </div>

  <div class="grid account-menu-main-container lg:account-menu-main-container">
    <div class="hidden sm:hidden md:hidden lg:block xl:block lg:col-4 xl:col-3">
      <span class="block mb-4 lg:account-page-title">Account & Profile</span>
      <div class="flex flex-column">
        @for (menu of menus; track menu) {
          <a
            [routerLink]="menu.path"
            class="flex account-menu-item lg:account-menu-item xl:account-menu-item"
            routerLinkActive="account-active-menu"
            [routerLinkActiveOptions]="{ exact: false }"
          >
            {{ menu.label }}
          </a>
        }
      </div>
    </div>
    <div class="col-12 sm:col-12 md:col-12 lg:col-8 xl:col-9">
      <div
        class="lg:account-main-content xl:account-main-content border-round {{
          maxContentWidth
        }}"
      >
        <router-outlet />
      </div>
    </div>

    <div
      *ngIf="invoiceTableDisplay"
      class="hidden sm:hidden md:hidden lg:block xl:block lg:col-4 xl:col-3"
    ></div>
    <div
      *ngIf="invoiceTableDisplay"
      class="col-12 sm:col-12 md:col-12 lg:col-8 xl:col-9 payment-info-invoices"
    >
      <span class="block mt-4 mb-4 payment-info-invoice-title">Invoices</span>

      <div class="payment-info-invoice-table">
        <p-table [value]="invoices">
          <ng-template pTemplate="header">
            <tr class="prepade-table-header">
              <th>DATE</th>
              <th>INVOICE NUMBER</th>
              <th>TOTAL AMOUNT</th>
              <th></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-invoice>
            <tr class="prepade-table-row">
              <td>{{ invoice.effective_at * 1000 | date: 'mediumDate' }}</td>
              <td>{{ invoice.number }}</td>
              <td>
                {{
                  invoice.total / 100 | currency: invoice.currency.toUpperCase()
                }}
              </td>
              <td>
                <p-button
                  icon="pi pi-download"
                  [text]="true"
                  [style]="{ color: 'black' }"
                  (onClick)="
                    downloadInvoicePdf(invoice.invoice_pdf, invoice.number)
                  "
                />
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="4" class="text-center">
                There is no invoices at this time.
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>
