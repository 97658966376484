import {
  ApplicationConfig,
  provideZoneChangeDetection,
  APP_INITIALIZER,
} from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import {
  accessTokenInterceptor,
  baseApiUrlInterceptor,
  errorApiHandlerInterceptor,
  mapApiResponseInterceptor,
} from '@libs/interceptors';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { AuthService } from './modules/auth/services/auth.service';
import { facebookInitializer } from './libs/helpers/facebook-Initializer.helper';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { environment } from 'environments/environment';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';
import { NotificationService } from '@libs/services/notification.service';

export const appConfig: ApplicationConfig = {
  providers: [
    MessageService,
    DialogService,
    NotificationService,
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideAnimations(),
    provideHttpClient(
      withInterceptors([
        baseApiUrlInterceptor,
        accessTokenInterceptor,
        mapApiResponseInterceptor,
        errorApiHandlerInterceptor,
      ]),
    ),
    {
      provide: APP_INITIALIZER,
      useFactory: facebookInitializer,
      multi: true,
      deps: [AuthService],
    },
    provideFirebaseApp(() => initializeApp(environment.FIREBASE_CONFIG)),
    provideMessaging(() => getMessaging()),
  ],
};
