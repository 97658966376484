import { CommonModule, DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { CardComponent } from '@libs/components';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import {
  DateRangeDurationInput,
  DateRangeDurationPipe,
} from '../../libs/pipes/date-range-duration.pipe';
import { MyTicketsService } from '@modules/my-tickets/services/my-tickets.service';
import { AirportCityPipe } from '@libs/pipes/airport-city.pipe';
import { DateAirportLocalePipe } from '@libs/pipes/date-airport-locale.pipe';

export interface FlightTimeRange {
  start: Date;
  end: Date;
  startStr?: string | null;
  endStr?: string | null;
}

export interface FlightHistory {
  id: string;
  aircraft: string;
  arrivalAt: string;
  arrivalDateLocale: string;
  arrivalTimeLocale: string;
  arrivalIataCode: string;
  arrivalCity: string;
  arrivalTerminal: string;
  boardingGroup: string;
  boardingPass: string;
  boardingTime: string;
  confirmNumber: string;
  departureAt: string;
  departureDateLocale: string;
  departureTimeLocale: string;
  departureIataCode: string;
  departureCity: string;
  flightNumber: string;
  gate: string;
  position: string;
  travelerName: string;
  type: string;
  // durationRange: DateRangeDurationInput | null;
  duration: string;
}

@Component({
  selector: 'app-flight-history',
  standalone: true,
  imports: [
    CommonModule,
    CardComponent,
    TableModule,
    IconFieldModule,
    InputIconModule,
    InputTextModule,
    DateRangeDurationPipe,
    AirportCityPipe,
    DateAirportLocalePipe,
  ],
  templateUrl: './flight-history.component.html',
  styleUrl: './flight-history.component.scss',
})
export class FlightHistoryComponent implements OnInit {
  loading = false;
  data: FlightHistory[] = [];
  searchValue: string | undefined;

  constructor(
    private _myTicketsService: MyTicketsService,
    private _datePipe: DatePipe,
    private _airportCity: AirportCityPipe,
    private _dateRangeDuration: DateRangeDurationPipe,
    private _dateAirportLocale: DateAirportLocalePipe,
  ) {}

  ngOnInit(): void {
    this.loading = true;

    this._myTicketsService.getFlightHistory().subscribe({
      next: (res) => {
        this.data = res;
        for (let history of this.data) {
          const duration: DateRangeDurationInput = {
            start: new Date(history.departureAt),
            end: new Date(history.arrivalAt),
          };

          history.departureCity = this._airportCity.transform(
            history.departureIataCode,
          );
          history.arrivalCity = this._airportCity.transform(
            history.arrivalIataCode,
          );
          history.duration = this._dateRangeDuration.transform(duration);
          history.departureDateLocale = this._dateAirportLocale.transform(
            history.departureAt,
            history.departureIataCode,
            'MMMM DD, YYYY',
          );
          history.departureTimeLocale = this._dateAirportLocale.transform(
            history.departureAt,
            history.departureIataCode,
            'h:mm a',
            true,
          );
          history.arrivalDateLocale = this._dateAirportLocale.transform(
            history.arrivalAt,
            history.arrivalIataCode,
            'MMMM DD, YYYY',
          );
          history.arrivalTimeLocale = this._dateAirportLocale.transform(
            history.arrivalAt,
            history.arrivalIataCode,
            'h:mm a',
            true,
          );
        }
      },
    });

    this.loading = false;
  }

  getRandomDateWithinHours(start: Date, hours: number): Date {
    const startTime = start.getTime();
    const endTime = startTime + hours * 60 * 60 * 1000; // Convert hours to milliseconds
    const randomTime = Math.random() * (endTime - startTime) + startTime;
    return new Date(randomTime);
  }

  generateRandomFlightDurationDateRange(): {
    flightStartDate: Date;
    flightEndDate: Date;
  } {
    const today = new Date();

    // Generate a random start date (could be now or later)
    const flightStartDate = this.getRandomDateWithinHours(today, 7); // Random date within the next 7 hours

    // Generate a random number of hours to add (1 to 7)
    const hoursToAdd = Math.floor(Math.random() * 7) + 1; // Random hours from 1 to 7

    // Calculate the end date
    const flightEndDate = new Date(flightStartDate);
    flightEndDate.setHours(flightEndDate.getHours() + hoursToAdd);

    return { flightStartDate, flightEndDate };
  }

  getRandomDate(start: Date, end: Date): Date {
    const startTime = start.getTime();
    const endTime = end.getTime();
    const randomTime = Math.random() * (endTime - startTime) + startTime;
    return new Date(randomTime);
  }

  generateRandomTripDateRange(): { tripStartDate: Date; tripEndDate: Date } {
    const today = new Date();
    const minDays = 1;
    const maxDays = 3;

    // Generate a random start date within the next 7 days
    const tripStartDate = this.getRandomDate(
      today,
      new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7),
    );

    // Generate a random number of days to add (1 to 3)
    const daysToAdd =
      Math.floor(Math.random() * (maxDays - minDays + 1)) + minDays;

    // Calculate the end date
    const tripEndDate = new Date(tripStartDate);
    tripEndDate.setDate(tripEndDate.getDate() + daysToAdd);

    return { tripStartDate, tripEndDate };
  }
}
