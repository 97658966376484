import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-my-tickets',
  standalone: true,
  imports: [RouterOutlet],
  template: '<div class="content-container"><router-outlet /></div>',
})
export class MyTicketsComponent {}
